<template>
  <div class="card grid-x align-center align-middle" @click="openForm">
    <div class="cell shrink">
      <div class="button-wrapper">
        <plus-circle-icon />
        <span class="button-text">{{ create }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOwner: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    create() {
      return this.$t('campaigns.cards.create');
    },
  },
  methods: {
    openForm() {
      this.$emit('create');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-card-create.scss";
</style>
